import React from 'react';
import loginStyles from '../../../stylesheets/components/login.module.scss';
import LoginHeader from './LoginHeader';
import LoginFooter from './LoginFooter';
import { useLocation } from 'react-router-dom';
import { Alert } from '@mui/material';

interface LoginBodyProps {
  children: React.ReactNode;
}

const LoginBody = ({ children }: LoginBodyProps) => {
  const location = useLocation();
  return (
    <div id="login-body" className={loginStyles.loginBody}>
      <LoginHeader />
      <div>
        {location.state?.prevPage === 'activate' && (
          <Alert severity="error">Login activation link is invalid.</Alert>
        )}
        {children}
      </div>
      <LoginFooter />
    </div>
  );
};

export default LoginBody;
